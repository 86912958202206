const data = [
  {
    name: "Divya Sharma",
    agent: "Done by Suresh",
    status: "Active",
    amount: "₹40,000",
    date: "12/03/2023",
    action: "Pay",
    pan: "BBDEW8735N",
    phone: "9876543216",
    aadhaar: "1234268863683",
    dob: "23/10/1991",
    pincode: "110078",
    loanfor: "personal",
    account: "345678901238",
    ifsc: "SBIN0067C",
    profession: "salaried",
    email: "abc@gmail.com",
  },
  {
    name: "Shanice Gupta",
    agent: "Assigned to Ramesh",
    status: "Pending",
    amount: "₹30,000",
    date: "18/01/2023",
    action: "Pay",
    pan: "BHUBC8726Z",
    phone: "9876543343",
    aadhaar: "1742167862693",
    dob: "23/10/1991",
    pincode: "110078",
    loanfor: "personal",
    account: "345678901238",
    ifsc: "SBIN0067C",
    profession: "business",
    email: "abc@gmail.com",
  },
  {
    name: "Barkha Shokeen",
    agent: "Done by Ramesh",
    status: "Active",
    amount: "₹50,000",
    date: "21/01/2023",
    action: "Pay",
    pan: "BWSBU8735J",
    phone: "9876545417",
    aadhaar: "125428862694",
    dob: "23/10/1991",
    pincode: "110078",
    loanfor: "personal",
    account: "345678901238",
    ifsc: "SBIN0067C",
    profession: "salaried",
    email: "abc@gmail.com",
  },
  {
    name: "Lokesh Perrier",
    agent: "Done by Suresh",
    status: "Active",
    amount: "₹60,000",
    date: "19/12/2022",
    action: "Pay",
    pan: "IEBAM8729X",
    phone: "9876541579",
    aadhaar: "735267862915",
    dob: "23/10/1991",
    pincode: "110078",
    loanfor: "personal",
    account: "345678901238",
    ifsc: "SBIN0067C",
    profession: "business",
    email: "abc@gmail.com",
  },
  {
    name: "Miriam Eric",
    agent: "Assigned to Suresh",
    status: "Pending",
    amount: "₹80,000",
    date: "02/12/2022",
    action: "Pay",
    pan: " HDBEJ8735S",
    phone: "9876547532",
    aadhaar: "123534544351",
    dob: "23/10/1991",
    pincode: "110078",
    loanfor: "personal",
    account: "345678901238",
    ifsc: "SBIN0067C",
    profession: "salaried",
    email: "abc@gmail.com",
  },
  {
    name: "Richard Gran",
    agent: "Assigned to Dinesh",
    status: "Pending",
    amount: "₹1,00,000",
    date: "09/11/2022",
    action: "Pay",
    pan: "BWEPG8735N",
    phone: "9876548532",
    aadhaar: "123567864353",
    dob: "23/10/1991",
    pincode: "110078",
    loanfor: "personal",
    account: "345678901238",
    ifsc: "SBIN0067C",
    profession: "business",
    email: "abc@gmail.com",
  },
];
export default data;
