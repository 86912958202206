const data = [
  {
    name: "Oliver Liam",
    address: "Karol bagh",
    email: "oliver@gmail.com",
    transaction: " FRB1237631",
    amount: "₹80,000",
    phone: "9876543216",
    date: " 02/01/2023",
    time: "09:02am",
  },
  {
    name: "Jaspreet Singh",
    address: "Vasant Vihar",
    email: "jassi@gmail.com",
    transaction: "  GEM1239319",
    amount: "₹20,000",
    phone: "9876543216",
    date: " 02/01/2023",
    time: "10:25am",
  },
  {
    name: "Samart Tyagi",
    address: " Laxmi Nagar ",
    email: "samart@gmail.com",
    transaction: "  HRC123566",
    amount: "₹60,000",
    phone: "9876543216",
    date: " 02/01/2023",
    time: "12:53pm",
  },
  {
    name: "Ajay Nagar",
    address: "Rajiv Nagar",
    email: "ajay@gmail.com",
    transaction: "  UVE1235369",
    amount: "₹70,000",
    phone: "9876543216",
    date: " 02/01/2023",
    time: "07:03pm",
  },
  {
    name: "Ethan James",
    address: "Shadi Pur",
    email: "oliver@gmail.com",
    transaction: "FRB1237631",
    amount: "₹10,000",
    phone: "9876543216",
    date: " 02/01/2023",
    time: "05:13pm",
  },
  {
    name: "Lucas Harper",
    address: "Nawada",
    email: "oliver@gmail.com",
    transaction: " BWC1936476",
    amount: "₹90,000",
    phone: "9826943210",
    date: " 02/01/2023",
    time: "03:00pm",
  },
];
export default data;
