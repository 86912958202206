const data = [
  {
    name: "Siddharth Koshy",
    address: "Karol bagh",
    action: "View",
    pan: "BBDEW8735N",
    phone: "9876543216",
    aadhaar: "1234268863683",
  },
  {
    name: "Ayaan Srivastava",
    action: "View",
    address: "Tilak Nagar",
    pan: "BHUBC8726Z",
    phone: "9876543343",
    aadhaar: "1742167862693",
  },
  {
    name: "Akshi Dora",
    action: "View",
    pan: "BWSBU8735J",
    address: "Pitam Pura",
    phone: "9876545417",
    aadhaar: "125428862694",
  },
  {
    name: "Arjun Sama",
    action: "View",
    address: "Janak Puri",
    pan: "IEBAM8729X",
    phone: "9876541579",
    aadhaar: "735267862915",
  },
  {
    name: "Minali Behl",
    action: "View",
    pan: " HDBEJ8735S",
    address: "Dwarka",
    phone: "9876547532",
    aadhaar: "123534544351",
  },
  {
    name: "Kavita Mann",
    action: "View",
    pan: "BWEPG8735N",
    address: "Najafgarh",
    phone: "9876548532",
    aadhaar: "123567864353",
  },
];
export default data;
